<template>
    <v-sheet>
        <v-row>
            <template v-for="(stepTitle, stepIdx) in stepTitles">
                <v-col style="background-color:#4E87DD; height:64px; padding-left:30px; color:#ffffff; clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);" v-bind:key="stepIdx" v-if="stepIdx == 0 && stepIdx == joinStep-1">
                    <p class="pb-0 mb-0" style="font: normal normal medium 14px/20px Apple SD Gothic Neo;">step {{stepIdx+1}}</p>
                    <p style="font: normal normal 600 16px/20px Apple SD Gothic Neo;">{{stepTitle}}</p>
                </v-col>
                <v-col style="background-color:#EEEEEE; height:64px; padding-left:30px; color:#555555; clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%);" v-bind:key="stepIdx" v-if="stepIdx == 0 && stepIdx != joinStep-1">
                    <p class="pb-0 mb-0" style="font: normal normal medium 14px/20px Apple SD Gothic Neo;">step {{stepIdx+1}}</p>
                    <p style="font: normal normal 600 16px/20px Apple SD Gothic Neo;">{{stepTitle}}</p>
                </v-col>
                <v-col style="background-color:#4E87DD; height:64px; padding-left:50px; color:#ffffff; clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%);" v-bind:key="stepIdx" v-if="stepIdx > 0 && stepIdx < stepTitles.length-1 && stepIdx == joinStep-1">
                    <p class="pb-0 mb-0" style="font: normal normal medium 14px/20px Apple SD Gothic Neo;">step {{stepIdx+1}}</p>
                    <p style="font: normal normal 600 16px/20px Apple SD Gothic Neo;">{{stepTitle}}</p>
                </v-col>
                <v-col style="background-color:#EEEEEE; height:64px; padding-left:50px; color:#555555; clip-path: polygon(0% 0%, 90% 0%, 100% 50%, 90% 100%, 0% 100%, 10% 50%);" v-bind:key="stepIdx" v-if="stepIdx > 0 && stepIdx < stepTitles.length-1 && stepIdx != joinStep-1">
                    <p class="pb-0 mb-0" style="font: normal normal medium 14px/20px Apple SD Gothic Neo;">step {{stepIdx+1}}</p>
                    <p style="font: normal normal 600 16px/20px Apple SD Gothic Neo;">{{stepTitle}}</p>
                </v-col>

                <v-col style="background-color:#4E87DD; height:64px; padding-left:50px; color:#ffffff; clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 10% 50%);" v-bind:key="stepIdx" v-if="stepIdx == stepTitles.length-1 && stepIdx == joinStep-1">
                    <p class="pb-0 mb-0" style="font: normal normal medium 14px/20px Apple SD Gothic Neo;">step {{stepIdx+1}}</p>
                    <p style="font: normal normal 600 16px/20px Apple SD Gothic Neo;">{{stepTitle}}</p>
                </v-col>
                <v-col style="background-color:#EEEEEE; height:64px; padding-left:50px; color:#555555; clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%, 10% 50%);" v-bind:key="stepIdx" v-if="stepIdx == stepTitles.length-1 && stepIdx != joinStep-1">
                    <p class="pb-0 mb-0" style="font: normal normal medium 14px/20px Apple SD Gothic Neo;">step {{stepIdx+1}}</p>
                    <p style="font: normal normal 600 16px/20px Apple SD Gothic Neo;">{{stepTitle}}</p>
                </v-col>
            </template>
        </v-row>
    </v-sheet>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
    name: 'join_stepper',
    components: {
    },
    data: () => ({
        stepTitles: ["번호 인증", "회원정보 입력", "070번호 발급", "결제카드 등록"]
    }),
    computed: {
        ...mapGetters({
            joinStep: 'join/getJoinStep',
        }),  
    },
    created () { 
    },
    mounted () { 
    },
    methods: {
    }
}
</script>
<style>
</style>


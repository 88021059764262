<template>
    <v-container
        class="py-8 px-6"
        fill-height
        fluid
    >
        <v-row align="center" justify="center">
            <v-col class="ml-12 mr-12" style="min-width:850px; max-width:1000px;">
                <v-card class="rounded-xl pa-10" elevation="5">
                    <v-card-text>
                        <p class="text-center" style="font-size:25px; color:#000000;">
                            <a @click="goHome" style="display: inline-block; float: left; padding-top: 5px; height: 15px;" v-if="joinStep < 5">
                                    <v-icon normal style="height: 20px;">mdi-home-outline</v-icon>
                                    <span style="color: #555555; padding-left: 5px; font: normal normal bold 16px/25px Apple SD Gothic Neo;">홈으로 이동 ></span>
                            </a>
                            콜 Pro 서비스 회원 가입
                        </p>

                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-text v-if="joinStep < 5">
                        <stepper></stepper>
                    </v-card-text>
                    <v-card-text>
                        <router-view></router-view>
                    </v-card-text>
                </v-card>
                <br/>
                <main-footer></main-footer>
            </v-col>
        </v-row>
    </v-container>

</template>
<script>
import {mapGetters} from 'vuex'
import Stepper from '@/components/join/tile_stepper';
import MainFooter from '@/components/common/main_footer';

export default {
    name: 'join',
    components: {
        Stepper, MainFooter
    },
    data: () => ({}),
    computed: {
        ...mapGetters({
            joinStep: 'join/getJoinStep',
        }),
    },
    created() {
        this.$store.commit('layoutFlag', false)
        this.$store.commit('menuId', 'join')
    },
    mounted() {
    },
    methods: {
        goHome() {
            this.$router.push('/')
        }
    }
}
</script>
<style>
</style>

